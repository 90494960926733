<template>
  <div>
    <section class="menu-block">
      <div id="menuCarousel" class="carousel slide" data-ride="carousel" data-interval="false">
        <div class="d-flex menu-nav">
          <button
            type="button"
            class="btn bg-white text-main shadow-sm"
            id="categoryToggle"
            @click="state.drawer = true"
          >
            <span class="material-icons-outlined">menu</span>
          </button>
          <el-menu
            :default-active="'' + state.activeCategoryId"
            class="menu-tabs carousel-indicators list-unstyled justify-content-center bg-white shadow-sm mx-0 pb-1 border-0 flex-grow-1 text-left"
            mode="horizontal"
            ref="menu"
            @select="handleSelect"
          >
            <template v-for="category in state.categories" :key="category.id">
              <el-menu-item
                :route="{ path: '/home/' + category.id }"
                :index="'' + category.id"
                :ref="
              (el) => {
                if (el) menu_items[category.id] = el;
              }
            "
              >{{ category.name }}</el-menu-item>
            </template>
          </el-menu>
        </div>

        <app-slider
          :changePage="handleSelect"
          :currentId="state.activeCategoryId"
          :list="state.categories"
          :afterChangePage="afterChangePage"
          class="mb-5"
        >
          <!-- <div class="text-right mt-3 mb-2 px-2">
        <el-radio-group v-model="layout_toggle">
          <el-radio-button label="0">
            <i class="material-icons-outlined">
                list
            </i>
          </el-radio-button>
          <el-radio-button label="1">
            <i class="material-icons-outlined">
              grid_view
            </i>
          </el-radio-button>
          <el-radio-button label="2">
            <i class="material-icons-outlined">
              image
            </i>
          </el-radio-button>
        </el-radio-group>
          </div>-->

          <div class="carousel-inner">
            <div class="carousel-item active">
              <el-empty :description="$t('PRODUCTS.NO_DATA')" v-if="state.isNoData === true"></el-empty>
              <div
                class="menu-list"
                v-if="state.isNoData === false"
                v-bind:class="{ 
                  'layout-list': layout_toggle == 0 , 
                  'layout-grid': layout_toggle == 1 , 
                  'layout-block': layout_toggle == 2 
                }"
              >
                <product-scroll
                  :getList="handleCurrentChange"
                  :page="state.productsPaginatedData.pagination.current_page"
                  :lastPage="state.productsPaginatedData.pagination.last_page"
                >
                  <template v-for="item in state.productsPaginatedData.data" :key="item.product_id">
                    <div :class="onGetSoldClass(item.stock)" :id="`product${item.product_id}`">
                      <router-link
                        :to="
                        item.stock === 0 ? '' : `/product/${item.product_id}`
                      "
                        @click.prevent="handleGetTarget(item)"
                      >
                        <div class="row align-items-center">
                          <app-image :image_url="item.image_url"></app-image>
                          <div class="col">
                            <div
                              class="title row no-gutters justify-content-between align-items-center"
                            >
                              <h3>{{ item.title }}</h3>
                              <div
                                class="status"
                                v-if="item.stock === 0"
                              >{{ $t("PRODUCTS.SOLD_OUT") }}</div>
                              <div class="status" v-else></div>
                            </div>
                            <!--
                          <div class="introduction">
                            {{ item.summary }}
                          </div>
                            -->
                            <div class="introduction" v-html="item.summary"></div>
                            <product-price :item="item"></product-price>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </template>
                </product-scroll>
              </div>
            </div>
          </div>
        </app-slider>
      </div>
    </section>
    <!--選單-->
    <nav class="main-menu row no-gutters align-items-center">
      <div class="col col-auto">
        <div class="btn-group">
          <button
            type="button"
            class="btn bg-white shadow text-main"
            id="categoryToggle"
            @click="state.drawer = true"
          >
            <span class="material-icons-outlined">menu</span>
          </button>
        </div>
      </div>
      <div class="col" v-if="shop_dm_mode_setting == null || shop_dm_mode_setting.data[0].cart===1">
        <a
          class="btn-checkOut btn btn-main btn-block px-5"
          @click="handleCartButton"
          v-if="state.cart_total >= 0 && !state.isEmptyCarts"
        >
          <!--v-if="state.cart_total > 0"-->
          <div class="row no-gutters">
            <div class="col col-auto">
              <span class="material-icons-outlined">shopping_cart</span>
              <span class="badge badge-pill badge-danger">{{}}</span>
            </div>
            <div class="col cart-go">
              {{ $t("PRODUCTS.ORDER_TOTAL") }}
              <span
                class="price cart-price"
              >{{ formatMoney(state.cart_total) }}</span>，按此結帳
            </div>
          </div>
        </a>
        <a
          class="btn-checkOut btn btn-secondary btn-block px-5"
          v-if="state.cart_total == 0 && state.isEmptyCarts"
        >
          <div class="row no-gutters">
            <div class="col col-auto">
              <span class="material-icons-outlined">shopping_cart</span>
              <span class="badge badge-pill badge-danger">{{}}</span>
            </div>
            <div class="col cart-go" style>
              {{ $t("PRODUCTS.ORDER_TOTAL") }}
              <span
                class="price cart-price"
              >{{ formatMoney(state.cart_total) }}</span>，按此結帳
            </div>
          </div>
        </a>
      </div>
      <div class="col text-center text-primary" v-else>
        目前為線上DM模式
        <br />歡迎在LINE留言諮詢商品
      </div>
      <el-drawer
        v-model="state.drawer"
        direction="ltr"
        size="80%"
        :with-header="false"
        :show-close="false"
      >
        <!--分類列表 SR-->
        <div class="category-block fixed-bottom" id="categoryBlock">
          <div
            class="category-close fixed-bottom d-flex justify-content-between align-items-center"
          >
            <button
              type="button"
              class="btn bg-main shadow text-white m-2"
              id="categoryClose"
              @click="state.drawer = false"
            >
              <span class="material-icons-outlined">close</span>
            </button>
          </div>

          <div class="category-list bg-white p-0">
            <div class="px-2">
              <div class="item">
                <a href="/store" @click="state.drawer = false">
                  <span class="material-icons-outlined">storefront</span>
                  {{ $t("COMMON.INFO") }}
                </a>
              </div>
              <div class="item">
                <el-button
                  @click=" state.drawer = false , state.dialogVisible = true"
                  class="border-0 p-0 w-100 text-left"
                >
                  <span class="material-icons-outlined">notifications</span>
                  店家公告
                </el-button>
              </div>
              <div class="item">
                <!-- <a href="/" @click="state.drawer = false"> -->
                <a href="/member" @click="state.drawer = false">
                  <span class="material-icons-outlined">person_outline</span>
                  {{ $t("COMMON.MEMBER_CENTER") }}
                </a>
              </div>
              <div class="item">
                <a href="/orders" @click="state.drawer = false">
                  <span class="material-icons-outlined">clear_all</span>
                  {{ $t("INDEX.ORDER") }}
                </a>
              </div>
              <small class="text-secondary d-inline-block mt-3">商品分類</small>
              <div class="item" v-for="category in state.categories" :key="category.id">
                <a :href="'/home/' + category.id" data-target="#menuCarousel">{{ category.name }}</a>
              </div>
            </div>
          </div>
        </div>
        <!--分類列表 END-->
      </el-drawer>
    </nav>
    <el-dialog title="公告訊息" center v-model="state.dialogVisible" width="85%">
      <div>
        <p
          class="text-center bg-danger text-white p-2"
          v-if="shopOpen.status === 0"
        >{{shopOpen.today}} 公休</p>

        <p class="text-center border border-primary text-main p-2" v-else>
          {{shopOpen.today}} 營業時間
          <br />
          <span v-html="shopOpen.openHours"></span>
        </p>
        <p class="mb-0">最新消息：</p>
        <!--
      <div class="news" v-if="'news' in shop_other">
        {{ shop_other.news }}
      </div>
        -->
        <div
          class="news"
          v-if="'news' in shop_other"
          v-html="shop_other.news.replace(/\n/g, '<br />\n')"
        ></div>
        <p class="mb-0 mt-2">目前開放點餐模式：</p>
        <p class="mb-0 display-4 text-main">{{ onGetShopSetting() }}</p>
        <p class="mb-0 mt-2">目前開放支付方式：</p>
        <p class="mb-0 display-4 text-main">{{ shopPay }}</p>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <div class="row" style="margin:0 -0.25rem 0.5rem">
            <div class="col-6 px-1">
              <router-link
                type="button"
                class="btn btn-secondary btn-block"
                data-dismiss="modal"
                to="/member"
                @click="state.dialogVisible = false"
              >會員中心</router-link>
            </div>
            <div class="col-6 px-1">
              <router-link
                type="button"
                class="btn btn-secondary btn-block"
                data-dismiss="modal"
                to="/orders"
                @click="state.dialogVisible = false"
              >訂單列表</router-link>
            </div>
          </div>

          <button
            type="button"
            class="btn btn-main btn-block"
            data-dismiss="modal"
            @click="state.dialogVisible = false"
          >立即點餐</button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style>
.el-dialog__body {
  padding: 12px 20px !important;
}
.el-menu-item {
  padding: 0 3px;
}
.el-menu--horizontal > .el-menu-item {
  float: none;
  line-height: normal;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid var(--main-color);
  color: var(--main-color);
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: var(--main-color);
}
.btn:hover {
  color: #ffffff;
}
.menu-list .item .introduction {
  word-break: break-all;
  white-space: break-spaces;
}
.cart-go {
  /* font-size: 3vw; */
  font-size: 0.8em;
}
.cart-price {
  /* font-size: 4vw; */
  font-size: 1.2em;
}
.category-list {
  min-height: 50%;
}
.category-list .el-button {
  min-height: inherit;
}
.category-close,
.category-list {
  width: 80%;
}
.category-top {
  word-break: keep-all;
  white-space: nowrap;
  overflow-x: auto;
}

.el-radio-group .el-radio-button .el-radio-button__inner {
  padding-top: 8px;
  padding-bottom: 8px;
}
.menu-nav {
  position: sticky;
  top: 0;
  border-bottom: 1px solid #e6e6e6;
  z-index: 99;
}
</style>
<script>
import {
  computed,
  inject,
  onMounted,
  reactive,
  ref,
  onBeforeUpdate,
  watch,
  nextTick,
} from "vue";

import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import storage from "@/utils/storage";
import { formatMoney } from "@/utils/tools";
import { useLogin } from "@/hook/auth";
import AppImage from "@/components/AppImage.vue";
import ProductScroll from "@/components/ProductScroll.vue";
import AppSlider from "@/components/AppSlider.vue";
import ProductPrice from "@/components/ProductPrice.vue";
import moment from "moment";

export default {
  name: "Home",
  components: {
    AppImage,
    ProductScroll,
    AppSlider,
    ProductPrice,
  },
  props: {
    isLoading: {
      type: Boolean,
    },
    // menu-list Layout by Zoe
    layout_toggle: {
      default: "1", //一進來預設GridView
    },
  },
  emits: ["onLoading"],
  setup(props, { emit }) {
    console.log("Home 開始～～～");
    const shop_setting = inject("shop_setting");
    const shop_other = computed(() => store.getters["shop/brand_other"]);
    console.log("AAAA shop_other.news: ", shop_other.news);
    //Add By Bryant
    const shop_dm_mode_setting = computed(
      () => store.getters["shop/brand_dm_mode_setting"]
    );
    const shop_pay = inject("shop_pay"); // 取得付款方式
    // console.log(shop_pay);
    const shop_open_hours = inject("shop_open_news");
    // console.log(shop_open_hours);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const id = route.params.id;
    let isOpenDialog = true;
    const pathFromName = storage.getItem("pathFromName");
    (pathFromName && pathFromName === "Product") ||
    pathFromName === "Orders" ||
    pathFromName === "Carts"
      ? (isOpenDialog = false)
      : (isOpenDialog = true);
    const state = reactive({
      page: 1,
      limit: 50,
      categories: [],
      cart_total: 0,
      drawer: false,
      dialogVisible: isOpenDialog ? true : false,
      weeksName: ["一", "二", "三", "四", "五", "六", "日"],
      defaultActiveCategoryId: null,
      activeCategoryId: null,
      productsPaginatedData: [],
      isNoData: null,
      cartButton: false,
      loading: true,
      isEmptyCarts: true,
    });
    const menu = ref(null);

    const { isLogin } = useLogin();

    //computed
    const myIsLoading = computed(() => {
      emit("onLoading", store.state.products.isLoading);
      return props.isLoading;
    });
    // 公告中營業時間顯示
    const shopOpen = computed(() => {
      let openInfoObj = {};
      const weekDay = moment().isoWeekday();
      const { hours, status } = shop_open_hours.value[weekDay - 1];
      // console.log(state.weeksName[weekDay - 1]);
      let openHours = "";
      hours.forEach((item, index) => {
        openHours += `${item.open} ~ ${item.close}`;
        index < hours.length - 1 ? (openHours += "<br />") : (openHours += "");
      });
      openInfoObj = {
        today: `${moment().format("MM/DD")}(${state.weeksName[weekDay - 1]})`,
        openHours,
        status,
      };
      return openInfoObj;
    });
    // 公告中的付款方式
    const shopPay = computed(() => {
      let payContent = "";
      const shopPayArr = Object.values(shop_pay.value).filter(
        (item) => item.status_diner === 1
      );
      shopPayArr.forEach((item, index) => {
        if (item.status_diner === 1) {
          payContent += item.name;
          index < shopPayArr.length - 1 ? (payContent += "、") : "";
        }
      });
      return payContent;
    });
    // const isMemberVip = computed(() => {
    //   return Number(store.state.member?.homepage?.is_vip) === 1;
    // });

    state.categories = computed(() => store.getters["categories/categories"]);
    state.defaultActiveCategoryId = computed(
      () => store.getters["categories/defaultCategoryId"]
    );
    state.cart_total = computed(() => store.getters["carts/cartTotal"]);
    // // 取得購物車使否為空
    // store.dispatch("carts/isEmptyCarts");
    state.isEmptyCarts = computed(() => store.getters["carts/isEmptyCarts"]);
    // console.log(state.cart_total, state.isEmptyCarts);
    state.isNoData = computed(() => store.getters["products/isNoData"]);
    state.productsPaginatedData = computed(
      () => store.getters["products/productsPaginatedData"]
    );
    // console.log(state.productsPaginatedData)
    //methods
    const fetchAllProducts = async (category_id, limit = state.limit) => {
      await store.dispatch("products/getProductsByCategoryId", {
        category_id: category_id,
        page: 1,
      });
      await fetchCategoryName(category_id);
      return true;
    };
    const fetchCategoryName = async (category_id) => {
      await store.dispatch("categories/getCategoryName", category_id);
    };

    const onGetSoldClass = (stock) => {
      let stock_class = "normal";
      if (stock === 0) stock_class = "stock-out";
      return "item " + stock_class;
    };
    const handleCurrentChange = async (page) => {
      await store.dispatch("products/addProductsByCategoryId", {
        category_id: state.activeCategoryId,
        page: page,
      });
    };
    const onGetShopSetting = () => {
      let msg = "";
      if (shop_setting.value.order.status === 1) msg += "現場點餐";
      if (shop_setting.value.here.status === 1) {
        if (msg !== "") msg += "、";
        msg += "預約內用";
      }
      if (shop_setting.value.togo.status === 1) {
        if (msg !== "") msg += "、";
        msg += "到店自取";
      }
      if (shop_setting.value.delivery.status === 1) {
        if (msg !== "") msg += "、";
        msg += "店家外送";
      }
      return msg;
    };
    const handleSelect = (key, isNext) => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setActiveCateogryIdToSession(key);
      fetchAllProducts(key);
    };

    const afterChangePage = (key, isNext) => {
      for (let key in menu_items.value) {
        const active = menu_items.value[key].active;
        if (active) {
          if (isNext) {
            menu.value.$el.scrollLeft += menu_items.value[key].$el.clientWidth;
          } else {
            menu.value.$el.scrollLeft -= menu_items.value[key].$el.clientWidth;
          }
          break;
        }
      }
    };

    const handleCartButton = () => {
      if (state.cart_total >= 0 && !state.isEmptyCarts) router.push("/carts");
      return false;
    };

    onMounted(async () => {
      const arr = [
        store.dispatch("categories/getCategories", 1),
        store.dispatch("categories/getDefaultCategoryId"),
        store.dispatch("carts/isEmptyCarts"),
      ];

      if (isLogin) {
        arr.push(store.dispatch("carts/getCarts"));
      }

      await Promise.all(arr).then(async () => {
        //取產品資料
        if (id !== undefined) await fetchAllProducts(id);
      });

      console.log("Home promise all 進入結束～～～");

      //是否跳公告
      // if (storage.getItem("dialog_open_hours", { type: "session" }) === null) {
      //   state.dialogVisible = false;
      //   storage.setItem("dialog_open_hours", true, { type: "session" });
      // }

      console.log("Home mounted 進入結束～～～");
    });

    const menu_items = ref([]);

    onBeforeUpdate(() => {
      menu_items.value = [];
    });

    const setActiveCateogryIdToSession = (id) => {
      state.activeCategoryId = id.toString();
      storage.setItem("category_id", id, {
        type: "session",
      });
    };

    if (id !== undefined) {
      setActiveCateogryIdToSession(id);
      state.activeCategoryId = id;
    }

    const handleGetTarget = (item) => {
      storage.setItem("productId", item.product_id);
      const target = document.querySelector(`#product${item.product_id}`);
      const scrollTop = target.offsetTop;
      storage.setItem("productTop", scrollTop);
    };
    watch(
      [
        () => state.defaultActiveCategoryId,
        () => state.productsPaginatedData,
        () => state.isNoData,
      ],
      (
        [newDefaultActiveCategoryId, newproductsPaginatedData, newisNoData],
        [oldDefaultActiveCategoryId, oldproductsPaginatedData, oldisNoData]
      ) => {
        if (
          id === undefined &&
          newDefaultActiveCategoryId > 0 &&
          oldDefaultActiveCategoryId === 0
        ) {
          fetchAllProducts(newDefaultActiveCategoryId);
          state.activeCategoryId = newDefaultActiveCategoryId;
          setActiveCateogryIdToSession(newDefaultActiveCategoryId);
        }

        if (newproductsPaginatedData !== oldproductsPaginatedData)
          state.loading = false;

        // console.log(
        //   "productsPaginatedData",
        //   newproductsPaginatedData,
        //   oldproductsPaginatedData
        // );
        // console.log("isNoData", newisNoData, oldisNoData);

        // console.log("監視著你～～～～～～～～～～～");
        // console.log('shop_dm_mode_setting', JSON.stringify(shop_dm_mode_setting));
        // console.log('shop_dm_mode_setting', shop_dm_mode_setting);
        // console.log('shop_other', shop_other.news);
      }
    );
    return {
      id,
      shop_other,
      shop_dm_mode_setting,
      shop_open_hours,
      myIsLoading,
      state,
      menu_items,
      shopOpen,
      shopPay,
      formatMoney,
      menu,
      onGetSoldClass,
      handleCurrentChange,
      handleSelect,
      handleCartButton,
      onGetShopSetting,
      afterChangePage,
      handleGetTarget,
    };
  },
};
</script>
